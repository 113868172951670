//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	// import shopCar from '../components/shopCar'
	import normalHead from '../../components/ishop/header.vue'
	import { mapMutations } from 'vuex';
	import { miceService } from '@/service/miceService'
	export default {
		data() {
			return {
				headValue: {
					name: '',
					haveBack: false,
					webView: ''
				},
				userName: '',
				tel: '',
				isDenialData: false,
			}
		},
		created() {
			this.accessAuthority()
		},
		mounted() {
			let pass = this.$store.state.ishop.pass
		},
		methods: {
			...mapMutations([
				'setPass'
			]),
			//登录
			_login() {
				let denialData = localStorage.getItem('denialTime')
				if(denialData) {
					this.accessAuthority()
				}
				if(this.isDenialData) {
					this.toast({
						message: '餐厅收集截止时间已过，感谢各位使用！',
						duration: 2000,
					});
				} else {
					this.loginStatus()
				}
			},
			loginStatus: async function() {
				if(!this.userName) {
					this.toast({
						message: '请输入登录账号',
						position: 'bottom',
						duration: 2000,
					});
					return;
				}
				let params = {
					client_id: "sites.ishop",
					client_secret: "smartx",
					grant_type: "password",
					userName: this.userName,
					password: this.userName
				}
				try {
					var res = await miceService.login(params)
					if(res.access_token) {
						sessionStorage.setItem("token", res.access_token)
						this.$router.push("/city")
					}
				} catch(err) {
					this.toast({
						message: '出错:' + err.error_description,
						position: 'bottom'
					})
				}
			},
			getLoginName() {
				var userName = '';
				userName = this.$route.query.userName == "null" ? '' : this.$route.query.userName;
				if(!userName) return false;
				else {
					this.userName = userName;
					this.password = null;
					this.headValue.webView = true;
					localStorage.setItem('webView', true);
					return true;
				}
			},
			accessAuthority() {
				let queryData = this.$route.query
				console.log(queryData, 'queryData')
				var channel = 'app';
				if(queryData.channel || localStorage.getItem('channel') || sessionStorage.getItem('channel')) {
					channel = queryData.channel ? queryData.channel : localStorage.getItem('channel') || sessionStorage.getItem('channel');
				}
				localStorage.setItem('channel', channel);
				this.setPass(channel)
				if(queryData.denialTime || localStorage.getItem('denialTime')) {
					if(queryData.denialTime) {
						localStorage.setItem('denialTime', queryData.denialTime);
					}
					let nowData = new Date()
					let denialData = new Date(localStorage.getItem('denialTime'))
					if(nowData <= denialData || channel == "app") {
						if(localStorage.getItem('webView')) {
							this.headValue.webView = true;
						}
						if(this.getLoginName()) {
							this.loginStatus();
						}
					} else {
						this.isDenialData = true
						this.$router.push("/errorPage")
					}
				} else {
					if(this.getLoginName()) {
						this.loginStatus();
					}
				}
				//其他站点跳转过来携带token
				if('token' in queryData){
					sessionStorage.setItem("token", queryData.token)
					this.$router.push("/city")
				}
				//其他站点跳转过来，是否采用iJsBridge关闭站点
				if('appSource' in queryData){
					sessionStorage.setItem("appSource", queryData.appSource);
					this.$router.push("/city")
				}
			}
		},
		components: {
			normalHead
		}
	}
